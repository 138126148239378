import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker"
import React from "react"
CheckWebpFeature()

export { wrapRootElement } from "./src/apollo/wrap-root-element"

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Avoid to scroll to top when next page trigger click
  // But this feature should condiser with details page back scroll too
  if (location.pathname.match(/properties\/for-sale/)) {
    return false
  }

  if (location.pathname.match(/properties\/for-rent/)) {
    return false
  }

  if (location.pathname.match(/properties-map\/for-sale/)) {
    return false
  }

  if (location.pathname.match(/properties-map\/for-rent/)) {
    return false
  }

  if (location.pathname.match(/off-plan-properties\/for-sale/)) {
    return false
  }

  if (location.pathname.match(/off-plan-properties-map\/for-sale/)) {
    return false
  }

  if (location.pathname.match(/holiday-homes\/for-sale/)) {
    return false
  }

  if (location.pathname.match(/holiday-homes\/for-rent/)) {
    return false
  }

  if (location.pathname.match(/holiday-homes-map\/for-sale/)) {
    return false
  }

  if (location.pathname.match(/holiday-homes-map\/for-rent/)) {
    return false
  }

  return true
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname === "/properties/for-sale/") {
    window.location.replace("/properties/for-sale/in-dubai/");
  }
  if (location.pathname === "/properties/for-rent/") {
    window.location.replace("/properties/for-rent/in-dubai/");
  }
}
