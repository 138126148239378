// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-off-plan-properties-for-sale-js": () => import("./../../../src/pages/off-plan-properties/for-sale.js" /* webpackChunkName: "component---src-pages-off-plan-properties-for-sale-js" */),
  "component---src-pages-properties-for-rent-js": () => import("./../../../src/pages/properties/for-rent.js" /* webpackChunkName: "component---src-pages-properties-for-rent-js" */),
  "component---src-pages-properties-for-sale-js": () => import("./../../../src/pages/properties/for-sale.js" /* webpackChunkName: "component---src-pages-properties-for-sale-js" */),
  "component---src-templates-areaguide-detail-template-js": () => import("./../../../src/templates/areaguide-detail-template.js" /* webpackChunkName: "component---src-templates-areaguide-detail-template-js" */),
  "component---src-templates-blog-detail-template-js": () => import("./../../../src/templates/blog-detail-template.js" /* webpackChunkName: "component---src-templates-blog-detail-template-js" */),
  "component---src-templates-career-detail-template-js": () => import("./../../../src/templates/career-detail-template.js" /* webpackChunkName: "component---src-templates-career-detail-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-offplan-details-template-js": () => import("./../../../src/templates/offplan-details-template.js" /* webpackChunkName: "component---src-templates-offplan-details-template-js" */),
  "component---src-templates-property-details-template-js": () => import("./../../../src/templates/property-details-template.js" /* webpackChunkName: "component---src-templates-property-details-template-js" */),
  "component---src-templates-team-details-template-js": () => import("./../../../src/templates/team-details-template.js" /* webpackChunkName: "component---src-templates-team-details-template-js" */)
}

